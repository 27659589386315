import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import Image1 from '../images/people/mhouse.jpg';
import Image2 from '../images/people/goeury.jpg';
import Image4 from '../images/people/verena.png';

import Image7 from '../images/people/martial.png';
import Image9 from '../images/people/svetlana.jpg';
import Image11 from '../images/people/simon.png';

import GoogleLogo from '../images/google-logo.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NeuchatelTestimonials = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    cssEase: 'Linear',
    speed: 12000,
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div id="prestations" className="ml-2 bg-gray mt-4">
      <div className="mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-4xl font-extrabold text-primarycolor">
            Témoignages clients
          </h2>
        </div>
        {/* <!-- cards carousel--> */}

        <SlideWrapper className="mt-4 lg:mt-24" style={{ marginTop: '4rem' }}>
          <Slider {...settings} className="ml-4">
            <Card className="card bg-white border border-gray-200 rounded shadow-lg p-2">
              <CardHeader className="card-heading">
                <img
                  src={Image1}
                  alt="milhouse
"
                />
                <div className="card-author">
                  <h2>milhouse</h2>
                  <p className="text-gray-400">
                    <span className="text-gray-500">Aspects positifs</span>:
                    Ponctualité, Professionnalisme, Qualité
                  </p>
                </div>
              </CardHeader>
              <div className="card-body mt-4">
                <p className="text-gray-600">
                  Excellent!!! Monsieur tres proffessionnel, gentil agreable et
                  surtout travail nikel.!!!! A recommander!!!
                </p>
              </div>
              <CardFooter className="card-footer">
                <p className="mr-4 text-gray-600">Évaluation</p>
                <a href="https://www.google.com/maps/place/Super+Serrurier+Gen%C3%A8ve/@46.2129744,6.1471136,15z/data=!4m5!3m4!1s0x0:0x75260e923ee521e1!8m2!3d46.2129744!4d6.1471136">
                  <img
                    src={GoogleLogo}
                    alt="Google reviews"
                    style={{ height: '25px', marginTop: '2px' }}
                  />
                </a>
                <div className="card-footer-stars">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                </div>
              </CardFooter>
            </Card>
            <Card className="card bg-white border border-gray-200 rounded shadow-lg p-2">
              <CardHeader className="card-heading">
                <img src={Image2} alt="Goeury Valentine" />
                <div className="card-author">
                  <h2>Goeury valentine</h2>
                  <p className="text-gray-400">
                    <span className="text-gray-500">Aspects positifs</span>:
                    Aspects positifs : Ponctualité, Qualité
                  </p>
                </div>
              </CardHeader>
              <div className="card-body mt-4">
                <p className="text-gray-600">
                  Service de grande qualité et rapport qualité prix incroyable,
                  rare ici en suisse. Très satisfaite du service et même au delà
                  de mes attentes, très arrangeant et ayant le sens du service.
                  Je recommande fortement!
                </p>
              </div>
              <CardFooter className="card-footer">
                <p className="mr-4 text-gray-600">Évaluation</p>
                <a href="https://www.google.com/maps/place/Super+Serrurier+Gen%C3%A8ve/@46.2129744,6.1471136,15z/data=!4m5!3m4!1s0x0:0x75260e923ee521e1!8m2!3d46.2129744!4d6.1471136">
                  <img
                    src={GoogleLogo}
                    alt="Google reviews"
                    style={{ height: '25px', marginTop: '2px' }}
                  />
                </a>
                <div className="card-footer-stars">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                </div>
              </CardFooter>
            </Card>
            <Card className="card bg-white border border-gray-200 rounded shadow-lg p-2">
              <CardHeader className="card-heading">
                <img src={Image2} alt="Guido Piazza" />
                <div className="card-author">
                  <h2>Guido Piazza</h2>
                  <p className="text-gray-400">
                    <span className="text-gray-500">Aspects positifs</span>:
                    Gentil, Rapport qualité-prix
                  </p>
                </div>
              </CardHeader>
              <div className="card-body mt-4">
                <p className="text-gray-600">
                  Super service super rapide super gentil: SUPER SERRURIER!
                  Merci. C’est rare de trouver de nos jours autant de
                  qualificatifs pour la même entreprise!
                </p>
              </div>
              <CardFooter className="card-footer mt-4">
                <p className="mr-4 text-gray-600">Évaluation</p>
                <a href="https://www.google.com/maps/place/Super+Serrurier+Gen%C3%A8ve/@46.2129744,6.1471136,15z/data=!4m5!3m4!1s0x0:0x75260e923ee521e1!8m2!3d46.2129744!4d6.1471136">
                  <img
                    src={GoogleLogo}
                    alt="Google reviews"
                    style={{ height: '25px', marginTop: '2px' }}
                  />
                </a>
                <div className="card-footer-stars">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                </div>
              </CardFooter>
            </Card>
            <Card className="card bg-white border border-gray-200 rounded shadow-lg p-2">
              <CardHeader className="card-heading">
                <img src={Image4} alt="Verena Ferraro" />
                <div className="card-author">
                  <h2>Verena Ferraro</h2>
                  <p className="text-gray-400">
                    <span className="text-gray-500">Aspects positifs</span>:
                    Rapidité, Application attentive aux détails
                  </p>
                </div>
              </CardHeader>
              <div className="card-body mt-4">
                <p className="text-gray-600">
                  J’ai envoyé un message d’urgence en pleine nuit et le matin
                  j’obtenais déjà un conseil personnalisé et un rdv pour
                  l’après-midi même ! Un travail propre et minutieux et qui plus
                  est avec le sourire ! Je recommande vivement :)
                </p>
              </div>
              <CardFooter className="card-footer mt-4">
                <p className="mr-4 text-gray-600">Évaluation</p>
                <a href="https://www.google.com/maps/place/Super+Serrurier+Gen%C3%A8ve/@46.2129744,6.1471136,15z/data=!4m5!3m4!1s0x0:0x75260e923ee521e1!8m2!3d46.2129744!4d6.1471136">
                  <img
                    src={GoogleLogo}
                    alt="Google reviews"
                    style={{ height: '25px', marginTop: '2px' }}
                  />
                </a>
                <div className="card-footer-stars">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                </div>
              </CardFooter>
            </Card>
          </Slider>
        </SlideWrapper>
        <SlideWrapper>
          <Slider {...settings} rtl={true} style={{ marginTop: '4rem' }}>
            <Card className="card bg-white border border-gray-200 rounded shadow-lg p-2">
              <CardHeader className="card-heading">
                <img src={Image7} alt="Martial" />
                <div className="card-author">
                  <h2>Salem Sam</h2>
                  <p className="text-gray-400">
                    <span className="text-gray-500">Aspects positifs</span>:
                    efficacité, excellent service, professionnalisme
                  </p>
                </div>
              </CardHeader>
              <div className="card-body mt-4">
                <p className="text-gray-600">
                  Ouf, un gros souci levé ! Bravo aux deux spécialistes
                  serruriers pour l’intervention efficace suite à un problème de
                  serrure. Merci pour l’excellent service ainsi que le rapport
                  qualité/prix. Je ne souhaite les revoir pour éviter de penser
                  à de nouveaux problèmes. Mais si problème devait survenir
                  alors ça les premiers à contacter sans hésitation vu leur
                  professionnalisme. Merci
                </p>
              </div>
              <CardFooter className="card-footer mt-4">
                <p className="mr-4 text-gray-600">Évaluation</p>
                <a href="https://www.google.com/maps/place/Super+Serrurier+Gen%C3%A8ve/@46.2129744,6.1471136,15z/data=!4m5!3m4!1s0x0:0x75260e923ee521e1!8m2!3d46.2129744!4d6.1471136">
                  <img
                    src={GoogleLogo}
                    alt="Google reviews"
                    style={{ height: '25px', marginTop: '2px' }}
                  />
                </a>
                <div className="card-footer-stars">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                </div>
              </CardFooter>
            </Card>
            <Card className="card bg-white border border-gray-200 rounded shadow-lg p-2">
              <CardHeader className="card-heading">
                <img src={Image9} alt="Sandrine Marchon" />
                <div className="card-author">
                  <h2>Sandrine Marchon</h2>
                  <p className="text-gray-400">
                    <span className="text-gray-500">Aspects positifs</span>:
                    Ponctualité, Professionnalisme, Qualité
                  </p>
                </div>
              </CardHeader>
              <div className="card-body mt-4">
                <p className="text-gray-600">
                  Merci au super serrurier qui est venu. Vous avez fait un
                  excellent travail. Je suis très satisfaite et je referai appel
                  à vous dans l avenir
                </p>
              </div>
              <CardFooter className="card-footer mt-4">
                <p className="mr-4 text-gray-600">Évaluation</p>
                <a href="https://www.google.com/maps/place/Super+Serrurier+Gen%C3%A8ve/@46.2129744,6.1471136,15z/data=!4m5!3m4!1s0x0:0x75260e923ee521e1!8m2!3d46.2129744!4d6.1471136">
                  <img
                    src={GoogleLogo}
                    alt="Google reviews"
                    style={{ height: '25px', marginTop: '2px' }}
                  />
                </a>
                <div className="card-footer-stars">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                </div>
              </CardFooter>
            </Card>
            <Card className="card bg-white border border-gray-200 rounded shadow-lg p-2">
              <CardHeader className="card-heading">
                <img src={Image2} alt="Guillaume SOLAREK" />
                <div className="card-author">
                  <h2>Guillaume SOLAREK</h2>
                  <p className="text-gray-400">
                    <span className="text-gray-500">Aspects positifs</span>:
                    Professionnalisme, Rapport qualité-prix
                  </p>
                </div>
              </CardHeader>
              <div className="card-body mt-4">
                <p className="text-gray-600">
                  Très professionnel et de bon conseil. En + du dépannage, a
                  tout tenté pour remettre l'installation en état. Aussi correct
                  au niveau prix. Je vous recommande sans hésiter.
                </p>
              </div>
              <CardFooter className="card-footer mt-4">
                <p className="mr-4 text-gray-600">Évaluation</p>
                <a href="https://www.google.com/maps/place/Super+Serrurier+Gen%C3%A8ve/@46.2129744,6.1471136,15z/data=!4m5!3m4!1s0x0:0x75260e923ee521e1!8m2!3d46.2129744!4d6.1471136">
                  <img
                    src={GoogleLogo}
                    alt="Google reviews"
                    style={{ height: '25px', marginTop: '2px' }}
                  />
                </a>
                <div className="card-footer-stars">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                </div>
              </CardFooter>
            </Card>
            <Card className="card bg-white border border-gray-200 rounded shadow-lg p-2">
              <CardHeader className="card-heading">
                <img src={Image11} alt="Simoneau Nicolas" />
                <div className="card-author">
                  <h2>Simoneau Nicolas</h2>
                  <p className="text-gray-400">
                    <span className="text-gray-500">Aspects positifs</span>:
                    Ponctualité, Professionnalisme, Qualité
                  </p>
                </div>
              </CardHeader>
              <div className="card-body mt-4">
                <p className="text-gray-600">
                  Efficacité !! Rapidité !!!! Et bon rapport qualité prix et
                  avec
                  <br />
                  le sourire.
                </p>
              </div>
              <CardFooter className="card-footer mt-4">
                <p className="mr-4 text-gray-600">Évaluation</p>
                <a href="https://www.google.com/maps/place/Super+Serrurier+Gen%C3%A8ve/@46.2129744,6.1471136,15z/data=!4m5!3m4!1s0x0:0x75260e923ee521e1!8m2!3d46.2129744!4d6.1471136">
                  <img
                    src={GoogleLogo}
                    alt="Google reviews"
                    style={{ height: '25px', marginTop: '2px' }}
                  />
                </a>
                <div className="card-footer-stars">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2889 7.74683L14.6938 6.74311L11.7455 0.484186C11.665 0.31282 11.5325 0.174096 11.3689 0.089773C10.9585 -0.122394 10.4598 0.0544119 10.2546 0.484186L7.30634 6.74311L0.711168 7.74683C0.52934 7.77403 0.363097 7.86379 0.235817 7.99979C0.0819425 8.16541 -0.00284927 8.38822 7.31104e-05 8.61927C0.00299549 8.85032 0.0933929 9.0707 0.251402 9.232L5.0231 14.1037L3.89576 20.9828C3.86932 21.1428 3.88623 21.3074 3.94457 21.4579C4.00291 21.6083 4.10035 21.7387 4.22583 21.8341C4.35131 21.9295 4.49981 21.9862 4.6545 21.9978C4.80919 22.0093 4.96388 21.9753 5.10102 21.8995L11.0001 18.6517L16.8991 21.8995C17.0601 21.9892 17.2472 22.0191 17.4264 21.9865C17.8784 21.9049 18.1823 21.4561 18.1043 20.9828L16.977 14.1037L21.7487 9.232C21.8786 9.09871 21.9643 8.92462 21.9903 8.73422C22.0604 8.2582 21.7435 7.81755 21.2889 7.74683Z"
                      fill="#FAC748"
                    ></path>
                  </svg>
                </div>
              </CardFooter>
            </Card>
          </Slider>
        </SlideWrapper>
      </div>
      <div className="flex justify-center">
        <a
          style={{ width: '325px' }}
          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md bg-secondarycolor shadow-sm border-gray-200 hover:bg-secondary-light text-white mt-6 lg:w-1/5 w-full z-10"
          href="https://www.google.com/search?q=stop+cambriolage&oq=sto&aqs=chrome.2.69i60j69i57j69i59l2j69i65j69i60j69i65l2.3738j0j7&sourceid=chrome&ie=UTF-8#lrd=0x478c2f5daf7b24df:0xbbe44c1bdd1afcf6,1,,,,"
          target={'_blank'}
          rel="noreferrer"
        >
          Consultez les commentaires clients
        </a>
      </div>
    </div>
  );
};

const Card = styled.div`
  border-radius: 5px;
  padding: 15px;
  margin-left: 30px;
  background: #fff;
  flex+wrap: wrap;


  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
`;
const CardHeader = styled.div`
  display: flex;

  & img {
    border-radius: 50%;
    height: 45px;
    width: 45px;
  }

  & div {
    margin-left: 20px;
  }
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;

  & .card-footer-stars {
    display: flex;
    margin-left: 15px;
  }
  & svg {
    width: 20px;
  }
`;

const SlideWrapper = styled.div`
  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      margin: 0.5rem;

      > div {
        height: 100%;
      }
    }
  }
`;
export default NeuchatelTestimonials;
